<!-- 新闻详情 -->
<template>
	<div class="xinWenXiangQing" id="xinWenXiangQing">
		<!-- 主体 -->
		<div class="page-content">
			<div class="main-content" v-if="contentInfo">
				<div class="path">
					<el-breadcrumb separator="-">
						<el-breadcrumb-item :to="{ path: `/${yiJiMenu_item.path}` }">{{yiJiMenu_item.menuName}}</el-breadcrumb-item>
						<!-- <el-breadcrumb-item>新闻中心</el-breadcrumb-item> -->
						<el-breadcrumb-item><div style="color: rgba(28, 115, 222, 1);">新闻详情</div></el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="new-box">
					<div class="title">{{ contentInfo.contentTitle }}</div>
					<div class="date-source">
						<div class="date">发布时间 : {{ getDate(contentInfo.createTime) }}</div>
						<div class="source">来源：{{ contentInfo.contentSource }}</div>
					</div>
					<div v-if="isMobile" class="rich-mobile" style="font-size: 16px;max-width: 1200px;margin: 39px auto;padding: 0 12px;" v-html="contentInfo.contentDetails && formatRichText(contentInfo.contentDetails)"></div>
					<div v-else class="rich-pc" style="font-size: 16px;max-width: 1200px;margin: 39px auto;" v-html="contentInfo.contentDetails"></div>
				</div>
				<div class="related-reading">
					<div class="heading">相关阅读</div>
					<div class="list">
						<div class="item" v-for="(item, index) in contentRandom" :key="index" @click="$router.push({ name: 'xinWenXiangQing', params: { id: item.id } })">
							<div class="title">{{ item.contentTitle }}</div>
							<div class="description">{{ item.contentDescription }}</div>
							<div class="date-source">{{ getDate(item.createTime) }}来源：{{ item.contentSource }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState,mapGetters} from 'vuex'
import {ContentInfo,ContentRandom} from '@/apis/wenZhangLanMu.js'
export default {
	props: ['id'],
	data() {
		return {
			contentInfo: null,
			contentRandom: [],

		};
	},
	computed: {
		...mapGetters(['getMenuItem']),
		...mapState({
			yiJiMenu_item(state) {
				return this.getMenuItem(state.yiJiMenu_path)
			}
		})
	},
	watch: {
		id() {
			this.getContentInfo()
			this.getContentRandom()
		}
	},
	created() {
		this.getContentInfo()
	},
	mounted() {
    new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: this.isMobile ? 0:150,
      mobile: true,
      live: false
    }).init();
  },
	methods: {
		getContentInfo() {
			ContentInfo(this.id).then(res => {
				this.contentInfo = res.data
				this.getContentRandom()
			})
		},
		getContentRandom() {
			ContentRandom(this.id,2).then(res => {
				this.contentRandom = res.data
			})
		}
	}
};
</script>

<style lang="scss" scoped>
@import './xinWenXiangQing.scss';
@import './xinWenXiangQingMedia.scss';
</style>
